<template>
  <ModalModule
    v-if="software"
    v-model="activeModal"
    :title="software.name"
    close-icon-text="close"
    :esc-to-close="true"
    :click-to-close="false"
    :is-full-screen="true"
    @abort="emits('closeModal')"
    @after-close="emits('afterCloseModal')"
  >
    <div v-if="!isDownloaded">
      <div v-if="software.abstract" class="row">
        <div class="col-lg-8 rte-text" v-html="software.abstract"></div>
      </div>
      <div class="row mt-5">
        <div class="col-6">
          <p
            v-if="software?.supportedOs && !(software?.supportedOs.length <= 1)"
          >
            <b>{{
              $textDictionary['downloadCenterSoftware.operationSystemLabel'] ??
              'downloadCenterSoftware.operationSystemLabel'
            }}</b>
          </p>

          <FormKit
            v-if="software?.supportedOs && !(software?.supportedOs.length <= 1)"
            type="form"
            :actions="false"
          >
            <FormKit
              v-model="selectedOs"
              type="select"
              name="sort-by"
              :options="software?.supportedOs"
              outer-class="is-secondary"
            />
          </FormKit>
        </div>
        <div class="col-6 d-flex justify-content-end align-items-end">
          <ShareButton
            :url="shareUrl ?? ''"
            :title="shareTitle ?? ''"
            :share-text="
              $textDictionary['softwareCenter.share.label'] ?? 'Share software'
            "
            :copied-text="
              $textDictionary['downloadCenter.copied.label'] ??
              'Copied to Clipboard'
            "
            :copy-text="
              $textDictionary['downloadCenter.copy.label'] ??
              'Copy download link'
            "
            @click="handleOnShareClick"
          />
        </div>

        <div class="row mt-5">
          <div class="col-12">
            <div v-if="!downloadLocked" class="custom-table rte-text">
              <table style="width: 100%; min-width: 800px">
                <thead>
                  <tr>
                    <th scope="col">
                      {{
                        $textDictionary[
                          'downloadCenterSoftware.operationSystemTableLabel'
                        ] ?? 'downloadCenterSoftware.operationSystemTableLabel'
                      }}
                    </th>
                    <th scope="col">
                      {{
                        $textDictionary[
                          'downloadCenterSoftware.formatTableLabel'
                        ] ?? 'downloadCenterSoftware.formatTableLabel'
                      }}
                    </th>
                    <th scope="col">
                      {{
                        $textDictionary[
                          'downloadCenterSoftware.furtherDownloadsTableLabel'
                        ] ?? 'downloadCenterSoftware.furtherDownloadsTableLabel'
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="asset of softwareLinks" :key="asset.os.name">
                    <td style="white-space: nowrap; max-width: 500px">
                      <Button
                        v-if="asset.formRequired && !downloadSoftware"
                        class="custom-button-link"
                        style-type="link"
                        icon-type="icon-left"
                        :icon-name="
                          asset.os.icon === 'macOs' ? 'mac' : asset.os.icon
                        "
                        @click="() => (downloadLocked = true)"
                      >
                        {{ asset.os.name }}
                      </Button>
                      <LinkElement
                        v-else
                        icon-type="icon-left"
                        :icon-name="
                          asset.os.icon === 'macOs' ? 'mac' : asset.os.icon
                        "
                        :link="{ uri: asset.format.uri }"
                        download
                      >
                        {{ asset.os.name }}
                      </LinkElement>
                    </td>

                    <td style="white-space: nowrap">
                      <Button
                        v-if="asset.formRequired && !downloadSoftware"
                        style-type="link"
                        class="custom-button-link"
                        icon-type="icon-left"
                        :icon-name="asset.format.icon"
                        @click="() => (downloadLocked = true)"
                      >
                        {{ asset.format.fileExtension }}
                      </Button>
                      <LinkElement
                        v-else
                        icon-type="icon-left"
                        :icon-name="asset.format.icon"
                        :link="{ uri: asset.format.uri }"
                        download
                        >{{ asset.format.fileExtension }} </LinkElement
                      >, {{ asset.format.readableFilesize }}
                    </td>
                    <td>
                      <template
                        v-for="singleDownload of asset.furtherDownloads"
                        :key="singleDownload.label"
                      >
                        <a :href="singleDownload.uri">{{
                          singleDownload.label
                        }}</a
                        ><br />
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <PardotFormIframe
              v-else-if="formUrl"
              :form-url="formUrl"
              @success="handleFormSuccess"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isDownloaded">
      <LibRichTextRenderer :rich-text="successfulDownloadHint" />
    </div>
  </ModalModule>
  <ModalModule
    v-else
    v-model="activeModal"
    title="Download Software"
    close-icon-text="close"
    :esc-to-close="true"
    :click-to-close="false"
    @abort="emits('closeModal')"
    @after-close="emits('afterCloseModal')"
  >
    <LibRichTextRenderer :rich-text="noDownloadFoundHint" />
  </ModalModule>
</template>

<script setup lang="ts">
import {
  Button,
  LinkElement,
  ModalModule,
  ShareButton
} from '@hypercodestudio/basler-components';
import PardotFormIframe from '~/components/downloads/software/PardotFormIframe.vue';
import type { SoftwareEntryModel } from '~/lib/DownloadService/model';

const {
  $textDictionary,
  $dictionary,
  $globalPageSettings,
  $currentEnvName,
  $analytics
} = useNuxtApp();
const logger = useLogger();
const locale = useLocale();

interface Props {
  software?: SoftwareEntryModel | null;
  open?: boolean;
  preferredOs?: string;
}

const props = defineProps<Props>();

const emits = defineEmits<{
  /**
   * Once the modal was close via the "close" button (is not triggered via "esc").
   */
  closeModal: [];
  /**
   * After the modal was closed (emits always, also while closing via "esc").
   */
  afterCloseModal: [];
}>();

const isDownloaded = ref(false);

const noDownloadFoundHint = $dictionary.value['downloadCenter.modal.notFound'];
const successfulDownloadHint =
  $dictionary.value['downloadCenter.modal.success'];

const downloadLocked = ref(false);
const downloadSoftware = ref(false);
const activeModal = ref(props.open);

const shareUrl = computed(() =>
  props.software?.id
    ? buildUrlString(
        locale.value,
        `${
          $globalPageSettings.value?.softwareCenterPage?.metadata?.slug ?? ''
        }/${props.software.id}`
      )
    : undefined
);
const shareTitle = computed(() => props.software?.name);

function findFurtherDownloads(supportedOs?: string[], platform?: string[]) {
  const filteredDownloads = props.software?.groupAssets?.filter((download) => {
    if (
      download.supportedOs?.includes(supportedOs?.[0] ?? '') &&
      download.platform?.includes(platform?.[0] ?? '') &&
      !download.isMainAsset
    ) {
      return download;
    }
  });
  const furtherDownloads = [];
  if (filteredDownloads) {
    for (const download of filteredDownloads) {
      furtherDownloads.push({
        label: download.name,
        uri: download.mediaLink
      });
    }
  }

  return furtherDownloads;
}

watch(
  () => props.open,
  () => {
    activeModal.value = props.open;

    if (!props.open) {
      // reset download state on close
      isDownloaded.value = false;
    }
  }
);

const { isMacOS, isAndroid, isWindows, userAgent } = useDevice();

const userOs = computed<string | undefined>(() => {
  if (isWindows) {
    return 'windows';
  }
  if (isMacOS) {
    return 'macOs';
  }
  if (isAndroid) {
    return 'android';
  }
  if (userAgent.toLowerCase().includes('linux')) {
    return 'linux';
  }
});

function getInitialOs() {
  if (
    props.preferredOs &&
    props.software?.supportedOs?.includes(props.preferredOs)
  ) {
    return props.preferredOs;
  }
  if (userOs.value && props.software?.supportedOs?.includes(userOs.value)) {
    return userOs.value;
  }

  return props.software?.supportedOs?.[0];
}

const selectedOs = ref<string | undefined>(getInitialOs());

const softwareLinks = computed(() => {
  const groupedAssets = [];
  if (!props.software) {
    return;
  }

  for (const entry of props.software.groupAssets!) {
    if (
      selectedOs.value &&
      entry.isMainAsset &&
      entry.supportedOs?.includes(selectedOs.value)
    ) {
      groupedAssets.push({
        formRequired: entry.formRequired,
        os: {
          icon: entry.supportedOs?.[0],
          name: `${entry.name} ${entry.supportedOs}`,
          uri: entry.mediaLink
        },
        format: {
          icon: 'download',
          fileExtension: entry.fileExtension,
          readableFilesize: entry.readableFilesize,
          uri: entry.mediaLink
        },
        furtherDownloads: findFurtherDownloads(
          entry.supportedOs,
          entry.platform
        )
      });
    }
  }
  return groupedAssets;
});

// e.g. 'https://info.baslerweb.com/l/73192/2020-02-24/98pcbf';
const formUrl = computed(() => {
  const urlString = $globalPageSettings.value?.documentsPardotFormUrl;
  if (!urlString) {
    return;
  }

  try {
    const formUrl = new URL(urlString);
    formUrl.searchParams.append('domain', $currentEnvName.value);

    const filename = props.software?.name;
    if (filename) {
      formUrl.searchParams.append('filename', filename);
    }

    return formUrl.toString();
  } catch (e) {
    logger.error('could not create new url from pardot url', e);
  }
});

function handleFormSuccess() {
  downloadLocked.value = false;
  downloadSoftware.value = true;
}

function handleOnShareClick() {
  if (props.software) {
    $analytics?.pushToDataLayer({
      event: 'share',
      ecommerce: {
        method: 'Software Center',
        item_id: `${props.software.id} | ${props.software.name}`
      }
    });
  }
}
</script>

<style>
.custom-button-link {
  .button__content {
    font-family: var(--font-family-body);
    font-weight: var(--font-weight-body);
    text-decoration: underline;
    padding: 0px;
    text-wrap: wrap;
    text-align: left;
  }
}
</style>
